import { Box, Card, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import React from 'react';
import useData from '../dataHook';

export default function Gallery() {
    let { gallery } = useData();

    return (
        <Box sx={{ mx: "5vw" }}>
            <Typography variant="h6" component="div">
                Gallery
            </Typography>
            <Divider />
            <Box sx={{ px: 3, pt: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}>
                {gallery.map(gallery => (
                    <Box key={gallery.title}>
                        <Card sx={{ border: 1, borderColor: "#CB5200", maxWidth: '27vw' }}>
                            {gallery.imageUrl && <CardMedia sx={{ objectFit: 'contain' }} component='img' image={gallery.imageUrl} alt={gallery.title} />}
                            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ flex: 1, display: 'flex' }}>
                                    <Typography flex={1} p={0} m={0} variant="h8" fontWeight='regular' component="div" alignContent="center" textAlign="center">
                                        {gallery.title}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}