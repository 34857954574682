import { Box, Card, CardContent, Container, Divider, Typography } from '@mui/material';
import React from "react";
import useData from "../dataHook";

export default function JoinUs() {
    const { joinus } = useData();

    return (
        <Container>
            {joinus.map((joinus) => (
                <Card key={joinus.title} sx={{ margin: 5 }} >
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                            {joinus.title}
                        </Typography>
                        <Divider />
                        <Box my={2} style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-evenly', columnGap: 5, flexWrap: 'wrap' }}>
                            {joinus.imageUrl && joinus.imageUrl.map(img => (
                                <img src={img} alt='' style={{ objectFit: 'contain', flex: 1, minWidth: '13vw', maxWidth: '18vw' }} />
                            ))}
                        </Box>
                        <Typography sx={{ pt: 2 }} textAlign='justify' component="div">
                            <div dangerouslySetInnerHTML={{ __html: joinus.description }} />
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </Container>
    )
}