import { Avatar, Box, ButtonBase, Container, Divider, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OverlaySpinner from '../components/OverlaySpinner';
import PublicationItem from '../components/PublicationItem';
import useData from '../dataHook';

export default function ProjectDetails() {
    let { projects, publications, team } = useData();
    const { id } = useParams();

    const project = projects.find(p => p.id === id);
    publications = publications.filter(p => project?.publications.includes(p.title));
    team = team.filter(t => project?.team.includes(t.id));

    const navigate = useNavigate();

    if (!project)
        return <OverlaySpinner />;

    return (
        <Container maxWidth='lg'>
            <Box>

                <Typography variant='h5'>{project.title}</Typography>
                <Divider />
                <Box my={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                    {project.imageUrl && <img src={project.imageUrl} alt='' style={{ flex: 1, width: '100%', objectFit: 'contain', height: 300 }} />}
                    <Typography flex={3} p={3}>{project.description}</Typography>
                </Box>

                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        {team.map(person => (
                            <Box xs={3} sx={{ flex: 1, maxWidth: 120 }} style={{ padding: 0, margin: 0 }}>
                                <ButtonBase
                                    onClick={() => { if (person.description) navigate(`/team/${person.id}`) }}
                                    style={{ padding: 5, height: '100%', width: '100%', alignItems: 'flex-start' }}
                                >
                                    <center>
                                        <Avatar alt={person.name}
                                            src={person.imageUrl}
                                            sx={{
                                                width: 60, height: 60,
                                                alignSelf: 'center',
                                                borderWidth: 1, borderColor: global.colors.primary,
                                            }}
                                        />
                                        <Typography fontSize={12} fontWeight='medium' textAlign='center' color={global.colors.primary}>{person.name}</Typography>
                                        <Typography fontSize={8} textAlign='center' color='#222'>{person.title}</Typography>
                                    </center>
                                </ButtonBase>
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', mt: 5 }}>
                    {project?.youtubeLinks.map(link =>
                        <Box key={link} style={{ flex: 1, width: '100%', maxWidth: 600, minWidth: 300, height: 300 }} px={2}>
                            <iframe style={{ width: '100%', height: '100%' }} src={link} title={project.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Box>)}
                </Box>

                <Box py={5}>
                    <Typography variant='h6'>Publications</Typography>
                    <Divider />
                    {publications.map(publication => <Box mt={2} key={publication.title}><PublicationItem publication={publication} /></Box>)}
                </Box>
            </Box>
        </Container>
    )
}