import { Avatar, Box, ButtonBase, CardMedia, Container, Divider, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OverlaySpinner from '../components/OverlaySpinner';
import ProjectItem from '../components/ProjectItem';
import PublicationItem from '../components/PublicationItem';
import useData from '../dataHook';

export default function ThemeDetails() {
    let { projects, publications, team, themes } = useData();
    const { id } = useParams();

    const theme = themes.find(t => t.id === id);
    publications = publications.filter(t => t.themes.includes(theme.title));
    projects = projects.filter(proj => proj.publications.filter(pub => publications.map(p => p.title).includes(pub)).length > 0);

    team = team.filter(t => theme?.team.includes(t.id) && !t.isAlumni);

    const navigate = useNavigate();

    if (!theme)
        return <OverlaySpinner />;

    return (
        <Container maxWidth='lg'>
            <Box>

                <Typography variant='h5'>{theme.title}</Typography>
                <Divider />
                <Box my={2} sx={{ display: 'flex', flexDirection: 'row', flexWrap: { xs: 'wrap', sm: 'wrap', md: 'nowrap' } }}>
                    {!theme.videoUrl && <CardMedia sx={{ width: '100%', maxHeight: 500, objectFit: 'contain', flex: 1 }} component='img' image={theme.imageUrl} alt={theme.title} />}
                    {theme.videoUrl && <CardMedia sx={{ width: '100%', height: 300, objectFit: 'contain', flex: 1 }} component='img' image={theme.videoUrl} alt={theme.title} />}
                    <Typography flex={3} p={3}>{theme.description}</Typography>
                </Box>

                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        {team.map(person => (
                            <Box xs={3} sx={{ flex: 1, maxWidth: 120 }} style={{ padding: 0, margin: 0 }}>
                                <ButtonBase
                                    onClick={() => { if (person.description) navigate(`/team/${person.id}`) }}
                                    style={{ padding: 5, height: '100%', width: '100%', alignItems: 'flex-start' }}
                                >
                                    <center>
                                        <Avatar alt={person.name}
                                            src={person.imageUrl}
                                            sx={{
                                                width: 60, height: 60,
                                                alignSelf: 'center',
                                                borderWidth: 1, borderColor: global.colors.primary,
                                            }}
                                        />
                                        <Typography fontSize={12} fontWeight='medium' textAlign='center' color={global.colors.primary}>{person.name}</Typography>
                                        <Typography fontSize={8} textAlign='center' color='#222'>{person.title}</Typography>
                                    </center>
                                </ButtonBase>
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box py={5}>
                    <Typography variant='h6'>Projects</Typography>
                    <Divider />
                    <Box sx={{ px: 5, pt: 2, flexDirection: 'row', display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                        {projects.map(project => <Box sx={{ display: 'flex', minWidth: { xs: 300, sm: 300, md: 400 }, flex: 1 }}>
                            <ProjectItem key={project.id} project={project} />
                        </Box>)}
                    </Box>
                </Box>

                <Box py={5}>
                    <Typography variant='h6'>Publications</Typography>
                    <Divider />
                    {publications.map(publication => <Box mt={2} key={publication.title}><PublicationItem publication={publication} /></Box>)}
                </Box>
            </Box>
        </Container>
    )
}