import { Box } from '@mui/material';
import React from 'react';
import ProjectItem from '../components/ProjectItem';
import useData from '../dataHook';

export default function Projects() {
    const { projects } = useData();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', px: { md: 5 }, mx: "5vw" }}>
            {projects.map(project => <Box sx={{ display: 'flex', minWidth: { xs: 300, sm: 300, md: 400 }, flex: 1 }}>
                <ProjectItem key={project.id} project={project} />
            </Box>)}
        </Box>
    );
}