import { Box } from '@mui/material';
import './App.css';
import Navbar from './components/Navbar';
import useData from './dataHook';

import {
	Route,
	BrowserRouter as Router,
	Routes
} from "react-router-dom";

import OverlaySpinner from './components/OverlaySpinner';
import Gallery from './screens/Gallery';
import Home from './screens/Home';
import JoinUs from './screens/JoinUs';
import ProjectDetails from './screens/ProjectDetails';
import Projects from './screens/Projects';
import Research from './screens/Research';
import Team from './screens/Team';
import ThemeDetails from './screens/ThemeDetails';

global.colors = {
	primary: '#CB5200',
};

export default function App() {
	const { isLoading } = useData();
	if (isLoading) {
		return <OverlaySpinner />;
	}

	return (
		<Box sx={{ pb: 5 }}>
			<Router>
				<Navbar />
				<Box sx={{ paddingTop: 10 }}>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/team" element={<Team />} />
						<Route path="/team/:id" element={<Team />} />
						<Route path="/projects" element={<Projects />} />
						<Route path="/projects/:id" element={<ProjectDetails />} />
						<Route path="/publications" element={<Research />} />
						<Route path="/joinus" element={<JoinUs />} />
						<Route path="/theme/:id" element={<ThemeDetails />} />
						<Route path="/gallery" element={<Gallery />} />
					</Routes>
				</Box>
			</Router>
		</Box>
	)
}
