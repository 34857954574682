import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Divider, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectItem from '../components/ProjectItem';
import PublicationItem from '../components/PublicationItem';
import useData from '../dataHook';

export default function Team() {
    const { id } = useParams();
    let { team, projects, publications } = useData();
    team = team.filter(m => !m.isHidden);
    const member = team.find(m => m.id === id);
    const navigate = useNavigate();

    if (!member)
        return (
            <Container maxWidth='lg' style={{ flex: 3 }}>
                <Typography variant="h4" sx={{ mt: 2 }}>Team</Typography>
                <Divider />
                <Box
                    sx={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}
                >
                    {team.map(member => (
                        <Card sx={{ margin: 5, maxWidth: 300, minWidth: 200, flex: 1 }}>
                            <CardActionArea>
                                {member.imageUrl && <CardMedia height={350} component='img' image={member.imageUrl} alt={member.name} />}
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {member.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {member.title}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => navigate(`/team/${member.id}`)}>Learn More</Button>
                                </CardActions>
                            </CardActionArea>
                        </Card>
                    ))}
                </Box>
            </Container>
        );

    member.projects = projects.filter(p => p.team.includes(member.id));
    member.publications = publications.filter(p => p.team.includes(member.id));

    return (
        <Container>
            <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, p: 1 }} >
                <CardMedia sx={{ flex: 2, maxWidth: 350, height: '100%', alignSelf: 'center' }} component='img' image={member.imageUrl} alt={member.name} />
                <CardContent sx={{ flex: 5, padding: 5, flexDirection: 'column', display: 'flex' }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {member.name}
                    </Typography>
                    <Divider />
                    <Typography variant='subtitle1' component="div">
                        {member.title}
                    </Typography>
                    <Typography gutterBottom variant='body2' component="div">
                        {member.email}
                    </Typography>
                    <Typography mt={2} flex={1} variant="body2" color="text.secondary">
                        {member.description}
                    </Typography>
                    {member.website && <Button sx={{ mt: 2, width: 200 }} variant="contained" onClick={() => window.open(member.website, '_blank')} >
                        Learn More
                    </Button>}
                </CardContent>
            </Card>

            {member?.projects.length > 0 && <>
                <Box py={5}>
                    <Typography variant='h6'>Projects</Typography>
                    <Divider />
                    <Box sx={{ px: 5, pt: 2, flexDirection: 'row', display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                        {member.projects?.map((project, index) => <Box sx={{ display: 'flex', minWidth: { xs: 300, sm: 300, md: 400 }, maxWidth: { md: '50%' }, flex: 1 }}>
                            <ProjectItem key={project.id} project={project} />
                        </Box>)}
                    </Box>
                </Box>
            </>}

            {member?.publications.length > 0 && <>
                <Typography mt={5} gutterBottom variant="h5" component="div">
                    Publications
                </Typography>
                <Divider />
                {member.publications?.map((publication, index) => (
                    <Box key={publication.title} mt={3}>
                        <PublicationItem publication={publication} />
                    </Box>
                ))}
            </>}

        </Container>
    )
}