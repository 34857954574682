import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ProjectItem({ project }) {
    const navigate = useNavigate();

    return (
        <Box sx={{ p: 2, width: '100%' }}>
            <Card>
                <CardActionArea onClick={() => navigate(`/projects/${project.id}`)}>
                    {project.imageUrl && <CardMedia sx={{ objectFit: 'contain' }} height={250} component='img' image={project.imageUrl} alt={project.title} />}
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {project.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "5",
                                WebkitBoxOrient: "vertical",
                            }}
                        >
                            {project.description}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={() => navigate(`/projects/${project.id}`)}>Learn More</Button>
                    </CardActions>
                </CardActionArea>
            </Card>
        </Box>
    )
}