import { Avatar, Box, ButtonBase, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import News from '../components/News';
import OverlaySpinner from '../components/OverlaySpinner';
import useData from '../dataHook';

export default function Home() {
    let { isLoading, themes, team } = useData();
    team = team.filter(m => !m.isHidden);
    const [activeTeam, setActiveTeam] = useState([]);
    const [, setActiveTheme] = useState();
    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: "2vw" }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', pb: 4, flexWrap: { xs: 'wrap', sm: 'wrap', md: 'nowrap' } }}>
                <Box sx={{ flex: 9 }}>
                    <Box>
                        <Typography sx={{ p: 5, textAlign: 'justify' }}>
                            Here at <b>W<b style={{ fontSize: 18, color: 'red' }}>i</b>reless, <b style={{ fontSize: 18, color: 'red' }}>S</b>ensing, & <b style={{ fontSize: 18, color: 'red' }}>E</b>mbedded <b style={{ fontSize: 18, color: 'red' }}>N</b>etworked <b style={{ fontSize: 18, color: 'red' }}>S</b>ystems (iSENS) Lab</b>, we create the next generation of intelligent wireless systems for communication and sensing with applications in autonomous systems,  mixed reality, and IoT.
                        </Typography>

                        <Box>
                            <Box px={5}>
                                <Typography variant="h6" component="div">
                                    Research Themes
                                </Typography>
                                <Divider />
                            </Box>

                            <Box sx={{ px: 5, pt: 2, flexDirection: 'row', display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                {themes.map(theme => (
                                    <Card key={theme.title} sx={{ flex: 1, minWidth: '20vw', backgroundColor: "#E2EBF7", border: 1, borderColor: "rgb(152, 157, 163)" }}>
                                        <CardActionArea
                                            onPointerEnter={() => { setActiveTeam(theme.team || []); setActiveTheme(theme); }}
                                            onPointerLeave={() => { setActiveTeam([]); setActiveTheme(); }}
                                            onClick={() => navigate('theme/' + theme.id)}
                                        >
                                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <Box sx={{ width: '100%' }}>
                                                    {!theme.videoUrl && <CardMedia sx={{ width: '100%', maxHeight: 500, objectFit: 'contain' }} component='img' image={theme.imageUrl} alt={theme.title} />}
                                                    {theme.videoUrl && <CardMedia sx={{ width: '100%', maxHeight: 500, objectFit: 'contain' }} component='img' image={theme.videoUrl} alt={theme.title} />}
                                                </Box>
                                            </Box>

                                            <CardContent>
                                                <Typography variant="h6" component="div" sx={{ fontSize: { xs: 13, lg: 20 } }}>
                                                    {theme.title}
                                                </Typography>
                                                <Typography variant="body2" fontSize={14} color="text.secondary" sx={{ display: { xs: "none", md: "block" } }}>
                                                    {theme.description}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                ))}
                            </Box>

                        </Box>

                    </Box>
                </Box>
                <Box sx={{ flex: 3, pt: 4, px: 2, flexDirection: 'column', alignItems: 'center' }}>
                    <News />

                    <Typography variant="h6" component="div" sx={{ mt: 5 }}>
                        Team
                    </Typography>
                    <Divider />
                    <Grid container sx={{ m: 0 }} spacing={4}>
                        {team.filter(t => !t?.isAlumni).map(person => (
                            <Grid item md={2.7} xs={5} style={{ padding: 2, margin: 0 }} key={person.name}>
                                <ButtonBase
                                    onPointerEnter={() => setActiveTeam([person.id])} onPointerLeave={() => setActiveTeam([])}
                                    onClick={() => { if (person.description) navigate(`/team/${person.id}`) }}
                                    style={{ padding: 5, height: '100%', width: '100%', alignItems: 'flex-start' }}
                                >
                                    <center>
                                        <Avatar alt={person.name}
                                            src={person.imageUrl}
                                            sx={{
                                                width: 75, height: 75,
                                                opacity: activeTeam.length > 0 ? (activeTeam.includes(person.id) ? 1 : 0.4) : 1,
                                                alignSelf: 'center',
                                                borderWidth: 1, borderColor: global.colors.primary,
                                            }}
                                        />
                                        <Typography fontSize={12} fontWeight='medium' textAlign='center' color={global.colors.primary}>{person.name}</Typography>
                                        <Typography fontSize={8} textAlign='center' color='#222'>{person.title}</Typography>
                                    </center>
                                </ButtonBase>
                            </Grid>
                        ))}
                    </Grid>

                    <Typography variant="h6" component="div" sx={{ mt: 5 }}>
                        Alumni
                    </Typography>
                    <Divider />
                    <Grid container sx={{ m: 0, }} spacing={0}>
                        {team.filter(t => t?.isAlumni).map(person => (
                            <Grid item xs={6} style={{ padding: 5, margin: 0, display: 'flex', alignItems: 'center' }} key={person.name}>
                                <ButtonBase
                                    onPointerEnter={() => setActiveTeam([person.id])} onPointerLeave={() => setActiveTeam([])}
                                    onClick={() => { if (person.description) navigate(`/team/${person.id}`) }}
                                    sx={{ width: '100%', justifyContent: 'flex-start' }}
                                >
                                    <Avatar alt={person.name}
                                        src={person.imageUrl}
                                        sx={{
                                            width: 50, height: 50,
                                            opacity: activeTeam.length > 0 ? (activeTeam.includes(person.id) ? 1 : 0.4) : 1,
                                            alignSelf: 'center',
                                            borderWidth: 1, borderColor: global.colors.primary,
                                        }}
                                    />
                                    <Box sx={{ p: 1 }}>
                                        <Typography fontSize={10} fontWeight='medium' textAlign='start' color={global.colors.primary}>{person.name}</Typography>
                                        <Typography fontSize={8} textAlign='start' color='#222'> {person.title}</Typography>
                                    </Box>
                                </ButtonBase>
                            </Grid>
                        ))}
                    </Grid>

                </Box>
            </Box>
            {isLoading && <OverlaySpinner />}
        </Box>
    )
}