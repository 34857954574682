import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 250;

export default function Navbar() {
	const [mobileOpen, setMobileOpen] = useState(false);
	const navigate = useNavigate();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const container = window.document.body;

	const navItems = [
		{ title: 'Home', url: '/' },
		{ title: 'Publications', url: '/publications' },
		{ title: 'Projects', url: '/projects' },
		{ title: 'Join Us', url: '/joinus' },
		{ title: 'Gallery', url: '/gallery' }
	];

	const drawer = (
		<Box onClick={handleDrawerToggle} px={2}>
			<Typography variant="h6" sx={{ my: 2, px: 2 }}>
				iSens
			</Typography>
			<Divider />
			<List>
				{navItems.map((item) => (
					<ListItem key={item.url} disablePadding>
						<ListItemButton onClick={() => navigate(item.url, { replace: true })}>
							<ListItemText primary={item.title} />
						</ListItemButton>
					</ListItem>
				))}
				<ListItem key={'login'} disablePadding>
					<ListItemButton onClick={() => { window.open('https://uillinoisedu.sharepoint.com/sites/SmartLabUIUC', '_blank') }} target="_blank">
						<ListItemText primary={'Login'} />
					</ListItemButton>
				</ListItem>
			</List>
		</Box>
	);

	return (
		<>
			<AppBar component="nav">
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: 'none' } }}
					>
						<MenuIcon />
					</IconButton>
					<Typography sx={{ flexGrow: 1 }}>
						<Button sx={{ color: 'white' }} onClick={() => navigate('/')}>
							<img src='/logo.png' alt='' style={{ width: 35, height: 35, marginRight: 5 }} />
							<Typography variant='h6' component='div' sx={{ textTransform: 'none' }}>
								iSENS @ UIUC
							</Typography>
						</Button>
					</Typography>
					<Box sx={{ display: { xs: 'none', sm: 'block' } }}>
						{navItems.map((item) => (
							<Button key={item.url} sx={{ color: 'white' }} onClick={() => navigate(item.url)}>
								{item.title}
							</Button>
						))}
						<Button sx={{ color: 'white' }} onClick={() => { window.open('https://uillinoisedu.sharepoint.com/sites/SmartLabUIUC', '_blank') }} target="_blank">
							Login
						</Button>
					</Box>
				</Toolbar>
			</AppBar>
			<Box component="nav">
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { sm: 'block', md: 'none' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
				>
					{drawer}
				</Drawer>
			</Box>
		</>
	)
}