import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";

export default function PublicationItem({ publication }) {
    return (
        <Card sx={{ display: 'flex', flexDirection: 'row' }}>
            <CardMedia sx={{ width: "15vw", objectFit: 'cover' }} component='img' image={publication.imageUrl || "/assets/img/projects/1.jpg"} alt={publication.title} />
            <Box sx={{ display: 'flex', flex: 1, height: '100%', flexDirection: 'column', flexWrap: { xs: 'wrap', sm: 'wrap', md: 'nowrap' } }}>
                <CardContent sx={{ flex: 1, m: 0, pt: 1 }}>
                    <Typography variant="h6" component="div" fontSize={16}>
                        {publication.title}
                    </Typography>
                    <Typography variant="subtitle1" fontSize={11} color="text.secondary">
                        {publication.authors}
                    </Typography>
                    <Typography variant="subtitle1" fontSize={12} color="text.secondary">
                        {publication.publication}
                    </Typography>
                </CardContent>
                <CardActions sx={{ pt: 0, pb: 1, m: 0 }}>
                    {publication.links.map(link => (
                        <Button size="small" onClick={() => window.open(link.url, '_blank')}>{link.title}</Button>
                    ))}
                </CardActions>
            </Box>
        </Card>
    )
}