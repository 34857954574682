import { useEffect, useState } from 'react';

export default function useData() {
    const [themes, setThemes] = useState([]);
    const [tags, setTags] = useState({});
    const [projects, setProjects] = useState([]);
    const [team, setTeam] = useState([]);
    const [publications, setPublications] = useState([]);
    const [news, setNews] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [barrier, setBarrier] = useState(9);
    const [isLoading, setLoading] = useState(true);
    const [joinus, setJoinUs] = useState([]);
    const [gallery, setGallery] = useState([]);

    useEffect(() => {
        fetch('/assets/themes.json', { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setThemes(data);
                setBarrier(b => b - 1);
            });
    }, []);

    useEffect(() => {
        fetch('/assets/tags.json', { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setTags(data);
                setBarrier(b => b - 1);
            });
    }, []);

    useEffect(() => {
        fetch('/assets/projects.json', { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setProjects(data);
                setBarrier(b => b - 1);
            });
    }, []);

    useEffect(() => {
        fetch('/assets/team.json', { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setTeam(data);
                setBarrier(b => b - 1);
            });
    }, []);

    useEffect(() => {
        fetch('/assets/publications.json', { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setPublications(data);
                setBarrier(b => b - 1);
            });
    }, []);

    useEffect(() => {
        fetch('/assets/news.json', { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setNews(data);
                setBarrier(b => b - 1);
            });
    }, []);

    useEffect(() => {
        fetch('/assets/sponsors.json', { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setSponsors(data);
                setBarrier(b => b - 1);
            });
    }, []);

    useEffect(() => {
        fetch('/assets/joinus.json', { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setJoinUs(data);
                setBarrier(b => b - 1);
            });
    }, []);

    useEffect(() => {
        fetch('/assets/gallery.json', { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setGallery(data);
                setBarrier(b => b - 1);
            });
    }, []);

    useEffect(() => {
        if (barrier <= 0)
            setTimeout(() => setLoading(false), 100);
    }, [barrier])

    return { isLoading, themes, news, tagsMap: tags, tags: Object.values(tags).flat(), projects, team, publications, sponsors, joinus, gallery };
}