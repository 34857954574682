import { Box, Button, Card, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import useData from '../dataHook';

export default function News() {
    const { news } = useData();

    if (news.length === 0)
        return <></>;

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'none' }}>
            <Typography variant="h6" component="div">
                News
            </Typography>
            <Divider />
            <Box sx={{
                display: 'flex', width: { xs: '80vw', sm: '80vw', md: '30vw' }, position: 'relative', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'scroll', gap: 3, p: 2, '::-webkit-scrollbar': {
                    width: '2px',
                    height: '3px'
                }
            }}>
                {news.map(news => (
                    <Box key={news.title} style={{ display: 'inline-block', width: { xs: '30vw', sm: '30vw', md: '9vw' } }}>
                        <Card sx={{ width: { xs: '30vw', sm: '30vw', md: '9vw' }, border: 1, borderColor: "#CB5200" }}>
                            {news.imageUrl && <CardMedia sx={{ height: 350, objectFit: 'contain' }} component='img' image={news.imageUrl} alt={news.title} />}
                            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ flex: 1, display: 'flex' }}>
                                    <Typography fontSize={10} mt={1} fontWeight='regular' component="div">
                                        {moment(news.date).format('MMM, YYYY')}
                                    </Typography>
                                </Box>
                                <Typography mt={1} variant="body2" color="text.secondary">
                                    <div dangerouslySetInnerHTML={{ __html: news.description }} />
                                </Typography>
                                {news.link && <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, }}>
                                    <Button size='small' href={news.link}>Learn more</Button>
                                </Box>}
                            </CardContent>
                        </Card>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}