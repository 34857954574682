import { Clear, Search } from '@mui/icons-material';
import { Box, Button, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import OverlaySpinner from "../components/OverlaySpinner";
import PublicationItem from "../components/PublicationItem";
import useData from "../dataHook";

export default function Research() {
    let { isLoading, publications, themes, tagsMap } = useData();
    const { state } = useLocation();
    const [selectedTags, setSelectedTags] = useState([]);
    const [search, setSearch] = useState("");

    if (selectedTags.length > 0)
        publications = publications.filter(p => selectedTags.every(t => p.tags.includes(t) || p.themes.includes(t)));

    if (search.length > 0)
        publications = publications.filter(p =>
            p.title.toLowerCase().includes(search.toLowerCase()) ||
            p.authors.toLowerCase().includes(search.toLowerCase())
        );

    const publicationYearMap = {};
    publications.forEach(publication => {
        const year = new Date(publication.date).getFullYear();
        if (year in publicationYearMap) publicationYearMap[year].push(publication);
        else publicationYearMap[year] = [publication];
    });

    useEffect(() => {
        if (state?.tags)
            setSelectedTags(state.tags);
    }, [state]);

    const countTagsOccurrences = (map, word) => {
        let count = 0;
        map.forEach(value => {
            if (value.tags && Array.isArray(value.tags)) {
                value.tags.forEach(tag => {
                    if (tag === word) {
                        count++;
                    }
                });
            }
        });
        return count;
    };

    const countThemesOccurrences = (map, word) => {
        let count = 0;
        map.forEach(value => {
            if (value.themes && Array.isArray(value.themes)) {
                value.themes.forEach(theme => {
                    if (theme === word) {
                        count++;
                    }
                });
            }
        });
        return count;
    };

    if (isLoading)
        return <OverlaySpinner />;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', mx: "5vw" }}>
            <Box sx={{ flex: 3 }}>
                <Typography variant="h4" sx={{ mt: 1 }}>Publications</Typography>
                <Divider />
                {Object.keys(publicationYearMap).sort((a, b) => b - a).map(year => (
                    <>
                        <Typography variant="h6" mt={3} fontSize={20}>{year}</Typography>
                        {publicationYearMap[year].map(publication => (
                            <Box key={publication.title} sx={{ mt: 2 }}>
                                <PublicationItem publication={publication} />
                            </Box>
                        ))}
                    </>
                ))}
            </Box>
            <Box sx={{ flex: 1, px: 5, display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <Box sx={{ width: 350, pt: 5 }}>

                    <Box sx={{ pt: 2, justifyContent: 'center' }}>
                        <TextField id="search" placeholder="Search..."
                            value={search} variant='standard'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                                endAdornment: search.length > 0 && (
                                    <InputAdornment position="end">
                                        <Button onClick={() => setSearch("")}>
                                            <Clear htmlColor="gray" />
                                        </Button>
                                    </InputAdornment>
                                )
                            }}
                            onChange={event => setSearch(event.target.value)}
                            sx={{ width: '100%', mb: 2 }}
                        />

                        <Box mt={2}>
                            <Typography>Themes</Typography>
                            <Divider />
                            <Box sx={{ display: 'inline-block' }}>
                                {themes.map(theme =>
                                    <Button onClick={() => setSelectedTags(tags => {
                                        if (tags.includes(theme.title))
                                            return tags.filter(t => t !== theme.title);
                                        return [...tags, theme.title];
                                    })}
                                        variant={selectedTags.includes(theme.title) ? 'contained' : 'text'}
                                        sx={{ fontSize: 10, p: 0.5, border: 1, m: 0.5 }}
                                    >
                                        {theme.title + " " + countThemesOccurrences(publications, theme.title)}
                                    </Button>
                                )}
                            </Box>
                        </Box>

                        <Box mt={2}>
                            <Typography>Publication Type</Typography>
                            <Divider />
                            <Box sx={{ display: 'inline-block' }}>
                                {tagsMap['publication']?.map(tag => (
                                    <Button onClick={() => setSelectedTags(tags => {
                                        if (tags.includes(tag))
                                            return tags.filter(t => t !== tag);
                                        return [...tags, tag];
                                    })}
                                        variant={selectedTags.includes(tag) ? 'contained' : 'text'}
                                        sx={{ fontSize: 10, p: 0.5, border: 1, m: 0.5 }}
                                    >
                                        {tag + " " + countTagsOccurrences(publications, tag)}
                                    </Button>
                                ))}
                            </Box>
                        </Box>

                        <Box mt={2}>
                            <Typography>Methodology</Typography>
                            <Divider />
                            <Box sx={{ display: 'inline-block' }}>
                                {tagsMap['methodology']?.map(tag => (
                                    <Button onClick={() => setSelectedTags(tags => {
                                        if (tags.includes(tag))
                                            return tags.filter(t => t !== tag);
                                        return [...tags, tag];
                                    })}
                                        variant={selectedTags.includes(tag) ? 'contained' : 'text'}
                                        sx={{ fontSize: 10, p: 0.5, border: 1, m: 0.5 }}
                                    >
                                        {tag + " " + countTagsOccurrences(publications, tag)}
                                    </Button>
                                ))}
                            </Box>
                        </Box>

                        <Box mt={2}>
                            <Typography>Application</Typography>
                            <Divider />
                            <Box sx={{ display: 'inline-block' }}>
                                {tagsMap['application']?.map(tag => (
                                    <Button onClick={() => setSelectedTags(tags => {
                                        if (tags.includes(tag))
                                            return tags.filter(t => t !== tag);
                                        return [...tags, tag];
                                    })}
                                        variant={selectedTags.includes(tag) ? 'contained' : 'text'}
                                        sx={{ fontSize: 10, p: 0.5, border: 1, m: 0.5 }}
                                    >
                                        {tag + " " + countTagsOccurrences(publications, tag)}
                                    </Button>
                                ))}
                            </Box>
                        </Box>

                        <Box mt={2}>
                            {selectedTags.length > 0 && <Button sx={{ fontSize: 11, px: 1 }} onClick={() => setSelectedTags([])} endIcon={<Clear />}>
                                Clear
                            </Button>}
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}